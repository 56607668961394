import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Careers from "./components/Careers";
import CopyRight from "./components/CopyRight";
import Header from "./components/Header";
import Home from "./components/Home";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SorderERP from "./components/SorderERP";
import SorderPrivacyPolicy from "./components/SorderPrivacyPolicy";
// import Home from "./theme2/Home";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/sorder-privacyPolicy" element={<SorderPrivacyPolicy />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/sorderERP" element={<SorderERP />} />
          {/* <Home /> */}
        </Routes>
      </BrowserRouter>
      <CopyRight />
    </div>
  );
}

export default App;
