import React, { useEffect } from "react";
import Footer from "./Footer";

import { Link } from "react-router-dom";

export default function SorderERP() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Header /> */}
      {/* <!-- ======== header start ======== --> */}
      <header className="header">
        <div className="navbar-area bg-dark">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <Link to="/" className="navbar-brand">
                    <img src="assets/img/logo/logo-color.png" alt="Logo" />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="pagescroll " href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          Products
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          Services
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          About
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          Clients
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link to="/careers" className="pagescroll">
                          Careers
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- navbar collapse --> */}
                </nav>
                {/* <!-- navbar --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- navbar area --> */}
      </header>
      {/* <!-- ======== header end ======== --> */}

      <div>
        {/* ============================== */}
        {/*********  SORDER Intro **********/}
        {/* ============================== */}
        <div className="container mt-5 pt-5">
          <div className="row mt-5 pt-5">
            <div className="col-md-6 col-xs-12 align-self-center ">
              <h1>SORDER ERP/SnD</h1>
              <p className="text-justify text-last-center mt-4 px-4">
                SORDER ERP is not a traditional sales and distribution
                management software. It is here to break the status quo.
                Notably, it is the first SAAS-based SnD and ERP solution for
                distribution and secondary sales management, designed to meet
                the distributors' and principals' needs and challenges.
                <br />
                SORDER ERP is not only an SnD system with add-on features of the
                ERP system, but it is a fully integrated cloud-hosted solution
                that aligns explicitly with a distributor's workflow. As a
                result, principals will see an increase in engagement,
                utilization, and efficiency of the Distributor. Additionally,
                users are likely to see increased sales and enhanced process
                visibility.
              </p>
            </div>
            <div className="col-md-6 d-flex d-md-block d-none">
              <img src="assets/img/careers.png" />
            </div>
          </div>
        </div>

        {/* ============================== */}
        {/*********  SORDER Benefits for Principal **********/}
        {/* ============================== */}
        <div className="container mt-5 pt-5">
          <div className="row mt-5 pt-5">
            <div className="text-start ms-3">
              <h1>Benefits of SORDER ERP/SnD for Principal</h1>
              <span className="underline"></span>
            </div>
            <div className="col-md-6 col-xs-12 align-self-center ">
              <div className="row">
                <div className="col-md-4 col-6">
                  <h1 className="color-01 pb-3">01</h1>
                  <p className="px-lg-3 ">
                    Making all important decisions in a timely manner.
                    Transparency and audit ready data availability in real time.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-02 pb-3">02</h1>
                  <p className="px-lg-3 ">
                    Access to a variety of information in real time i.e. sales,
                    performance, inventory and execution of data.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-03 pb-3">03</h1>
                  <p className="px-lg-3 ">
                    Benefits of cloud hosting in shape of centralized
                    management, easy deployment and visibility.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-04 pb-3">04</h1>
                  <p className="px-lg-3 ">
                    Effective management of large workforces.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-05 pb-3">05</h1>
                  <p className="px-lg-3 ">
                    Keeping all stakeholders on same page.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-06 pb-3">06</h1>
                  <p className="px-lg-3 ">
                    Increased performance and productivity.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex d-md-block d-none">
              <img src="assets/img/sorderERP/sorder-benefits.png" />
            </div>
          </div>
        </div>

        {/* ============================== */}
        {/*********  SORDER Benefits for Distributors **********/}
        {/* ============================== */}
        <div className="container mt-5 pt-5">
          <div className="row mt-5 pt-5">
            <div className="text-start ms-3">
              <h1>Benefits of SORDER ERP/SnD for Distributors</h1>
              <span className="underline"></span>
            </div>
            <div className="col-md-6 d-flex d-md-block d-none">
              <img src="assets/img/sorderERP/sorder-benefits-02.png" />
            </div>
            <div className="col-md-6 col-xs-12 align-self-center ">
              <div className="row">
                <div className="col-md-4 col-6">
                  <h1 className="color-01 pb-3">01</h1>
                  <p className="px-lg-3 ">
                    Automation to most of the tedious tasks.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-02 pb-3">02</h1>
                  <p className="px-lg-3 ">
                    Timely access to information and sales data.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-03 pb-3">03</h1>
                  <p className="px-lg-3 ">
                    Keeping up with changing market dynamics.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-04 pb-3">04</h1>
                  <p className="px-lg-3 ">
                    Availability of all core financial modules making financial
                    management easier.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-05 pb-3">05</h1>
                  <p className="px-lg-3 ">
                    Conducting quantitative and qualitative KPIs performance
                    audit.
                  </p>
                </div>

                <div className="col-md-4 col-6">
                  <h1 className="color-06 pb-3">06</h1>
                  <p className="px-lg-3 ">
                    Successfully keeping trak of actual field work.
                  </p>
                </div>
                <div className="col-md-4 col-6">
                  <h1 className="color-07 pb-3">07</h1>
                  <p className="px-lg-3 ">Guaging Sales Team performance.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* =============================== */}
        {/*********  Modules Details *******/}
        {/* =============================== */}

        <div className="container mt-5 pt-5">
          <div className="row mt-5 pt-5 text-start">
            <div className="col-md-6 ">
              <div className="text-start ">
                <h1>Modules: Details</h1>
                <span className="underline"></span>
              </div>
            </div>

            <div className="col-md-6 ">
              <h2 className="text-start pb-3">Sale Module</h2>
              <p className="px-lg-3 text-start ">
                Our sale module works with the Sorder Order Booking Management
                System. Sales orders are collected through Bookers mobile app or
                direct entries of Orders in SORDER Erp. Distributors can create
                and manage PJP and, based on the PJP system, can generate
                invoices.
              </p>
            </div>

            <div className="col-md-6 ">
              <h2 className=" pb-3">Purchase Module</h2>
              <p className="px-lg-3 ">
                Our purchasing module streamlines the procurement of required
                inventories and automates buying processes. These processes
                include Auto Generation of PO based on scheduled dates, Option
                to add Input of Distributors, and Calculating Order based on
                different parameters. This module can integrate with the
                inventory control module and the production planning module.
                This module is often also combined with supply chain management.
              </p>
            </div>

            <div className="col-md-6 ">
              <h2 className=" pb-3">Claims Module</h2>
              <p className="px-lg-3 ">
                Our claims module focuses on claims process analysis to provide
                effective claim handling. Principals can create multiple schemes
                to boost Sales and adjust the payout amounts through claims.
              </p>
            </div>

            <div className="col-md-6 ">
              <h2 className=" pb-3">Accounts Module</h2>
              <p className="px-lg-3 ">
                Our accounting software records and processes accounting
                transactions using accounts payable, accounts receivable, and
                payroll modules. Together, these modules function as an
                accounting information system.
              </p>
            </div>

            <div className="col-md-6 ">
              <h2 className=" pb-3">Inventory Module</h2>
              <p className="px-lg-3 ">
                Our inventory module assists with tracking stock, starting from
                receiving to dispatch and return. Multiple physical and virtual
                warehouse locations can be opened in the system for better
                visibility of stock movement.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
