import React from "react";
import { Link } from "react-router-dom";
import { phone, envalop, location } from "../assets/icons";
export default function Footer() {
  return (
    <footer className="footer" id="footer">
      {/*  */}
      <div className="container">
        <div className="widget-wrapper">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-6">
              <div className="footer-widget">
                <div className="logo mb text-start">
                  <span>
                    {/* <h1 className="text-white">THARSOL</h1> */}
                    <img
                      height="45px"
                      src="assets/img/logo/logo-light.png"
                      alt=""
                    />
                  </span>
                </div>
                <p className="desc  text-white text-start my-0">
                  <span className="fs-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      fill="currentColor"
                      class="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>{" "}
                  </span>{" "}
                  +92 41 8503012-14
                </p>
                <p className="desc  text-white text-start my-0 ">
                  <span className="fs-4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      fill="currentColor"
                      class="bi bi-envelope"
                      viewBox="0 0 16 16"
                    >
                      <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z" />
                    </svg>
                    &nbsp;{" "}
                  </span>
                  <a href="mailto:support@tharsol.com" className="text-white">
                    support@tharsol.com{" "}
                  </a>
                </p>
                <p className="desc mb-30 text-white text-start mt-2">
                  <span className=" align-top">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="20"
                      fill="currentColor"
                      class="bi bi-geo-alt"
                      viewBox="0 0 16 16"
                    >
                      <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
                      <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    </svg>{" "}
                    &nbsp;
                  </span>
                  <span className="text-white">
                    Office 217, {"  "}2nd floor, <br /> Mall of Faisalabad,{" "}
                    <br /> Faisalabad.
                  </span>
                </p>
                <ul className="socials">
                  <li>
                    <a href="#">
                      <i class="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  {/* <li>
                  <a>
                    <i class="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a>
                    <i class="fa-brands fa-instagram"></i>
                  </a>
                </li> */}
                  <li>
                    <a href="https://pk.linkedin.com/company/tharsol-pvt-ltd">
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-6 col-6">
              <div className="footer-widget">
                <h3>About Us</h3>
                <ul className="links">
                  <li>
                    <a href="/#home">Home</a>
                  </li>
                  <li>
                    <a href="/#products">Products</a>
                  </li>
                  <li>
                    <a href="/#services">Services</a>
                  </li>
                  <li>
                    <a href="/#about">About</a>
                  </li>
                  <li>
                    <a href="/careers">Careers</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-2 col-lg-2 col-md-6 col-6">
              <div className="footer-widget">
                <h3>Features</h3>
                <ul className="links">
                  <li>
                    <a href="javascript:void(0)">How it works</a>
                  </li>
                  <li>
                    <Link to="/privacyPolicy">MREP Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/sorder-privacyPolicy">
                      SORDER Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <a href="/privacyPolicy">Terms of service</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-6">
              <div className="footer-widget">
                <h3>Other Products</h3>
                <ul className="links">
                  <li>
                    <a>ERP - DMS</a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.sameelenterprises.mrep">
                      MREP
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.sameelenterprises.mrepgeochemist">
                      MREP GEO Chemist{" "}
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.tharsol.sorder">
                      Order Taking System - SORDER
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.tharsol.dlvr.dist">
                      Delivery System - DLVR
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.tharsol.mtalib">
                      M-Talib
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
