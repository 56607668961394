import React from "react";

export default function CopyRight() {
  return (
    <div className="" style={{ backgroundColor: "rgba(0,0,0,0.8)" }}>
      <span className="py-2 text-secondary copyright" id="copyright">
        Copyright © 2017 All Rights Reserved By{" "}
      </span>{" "}
      <a href="" onClick={() => window.location.reload()}>
        {" "}
        <span className="text-warning">Tharsol </span>{" "}
      </a>
    </div>
  );
}
