import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <header className="header">
      {/* <!-- ======== header start ======== --> */}
      <div className="navbar-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <nav className="navbar navbar-expand-lg">
                <Link to="/" className="navbar-brand">
                  <img src="assets/img/logo/logo-color.png" alt="Logo" />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                  <span className="toggler-icon"></span>
                </button>

                {/* <!-- navbar collapse --> */}
              </nav>
              {/* <!-- navbar --> */}
            </div>
          </div>
          {/* <!-- row --> */}
        </div>
        {/* <!-- container --> */}
      </div>
      {/* <!-- navbar area --> */}
      {/* <!-- ======== header end ======== --> */}
    </header>
  );
}
