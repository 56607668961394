const Jobs = [
  { title: "Sr. .Net Developer", positions: "3" },
  { title: "Jr. .Net Developer", positions: "4" },
  { title: "Jr. QA", positions: "3" },
  { title: "Support Team", positions: "1" },
];
const Clients = [
  {
    img: "assets/img/clients/agp.png",
  },
  {
    img: "assets/img/clients/allmed.png",
  },
  {
    img: "assets/img/clients/aspin.png",
  },
  {
    img: "assets/img/clients/amson-logo.png",
  },
  {
    img: "assets/img/clients/sanofi.png",
  },
  {
    img: "assets/img/clients/sante.png",
  },
  {
    img: "assets/img/clients/ccl.png",
  },
  {
    img: "assets/img/clients/servier.png",
  },
  {
    img: "assets/img/clients/chiesi.png",
  },
  {
    img: "assets/img/clients/medo_chemie.png",
  },
  {
    img: "assets/img/clients/obs-pak.png",
  },
 
  {
    img: "assets/img/clients/pharma5.png",
  },
  {
    img: "assets/img/clients/pacific.png",
  },
  {
    img: "assets/img/clients/nabiqasim.png",
  },
  // {
  //   img: "assets/img/clients/daneenpharma.png",
  // },
  {
    img: "assets/img/clients/phd-labs.png",
  },
  {
    img: "assets/img/clients/excellabs.png",
  },
  {
    img: "assets/img/clients/galaxy.png",
  },
  {
    img: "assets/img/clients/obs-pharma.png",
  },
  // {
  //   img: "assets/img/clients/glitz_logo.png",
  // },
  // {
  //   img: "assets/img/clients/globalpharma.png",
  // },
  {
    img: "assets/img/clients/helix.png",
  },
  {
    img: "assets/img/clients/herbion-logo.png",
  },
  {
    img: "assets/img/clients/hiltonpharma.png",
  },
  {
    img: "assets/img/clients/indus.png",
  },
  {
    img: "assets/img/clients/macter.png",
  },
  {
    img: "assets/img/clients/mcolson.png",
  },
  {
    img: "assets/img/clients/medlife.png",
  },
  {
    img: "assets/img/clients/neutropharma.png",
  },
  {
    img: "assets/img/clients/obs.png",
  },
  {
    img: "assets/img/clients/pharma5.png",
  },
  {
    img: "assets/img/clients/ferozsons-1.png",
  },
  {
    img: "assets/img/clients/pharmatec_logo.png",
  },
  {
    img: "assets/img/clients/pharmevo.png",
  },
  {
    img: "assets/img/clients/schazoozaka.png",
  },
  // {
  //   img: "assets/img/clients/searle.png",
  // },
  {
    img: "assets/img/clients/sjg.png",
  },
  {
    img: "assets/img/clients/swiss.png",
  },
  {
    img: "assets/img/clients/wimits.png",
  },
];

export default { Jobs, Clients };
