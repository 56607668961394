import React, { useEffect } from "react";
import Header from "./Header";

export default function SorderPrivacyPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <section id="about">
        {/* <!-- About 01 --> */}
        <div id="about-01">
          <div className="content-box-lg">
            <div className="container">
              <div className="row">
                {/* <!-- About Left Side --> */}
                <div
                  className="col-md-12 col-sm-12 wow slideInLeft"
                  data-wow-duration="2s"
                >
                  <div id="about-left" className="mt-5 pt-5">
                    <div className="vertical-heading">
                      <h5 className=" vertical-text me-5 pe-13 d-flex d-md-block d-sm-none d-none">
                        Privacy Policy
                      </h5>
                      <h2 className="text-start  ps-4 ">
                        <strong>Privacy Policy</strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- About Bottom --> */}
              <div className="row mt-5">
                <div className="col-md-12 wow fadeInUp" data-wow-duration="3s">
                  <div id="about-bottom">
                    <ol
                      className="container fs-14"
                      style={{ textAlign: "justify" }}
                    >
                      <li>
                        <b>We respect your privacy</b>

                        <ol className="bullet-def fs-14 ">
                          <li>
                            Tharsol respects your right to privacy and is
                            committed to safeguarding the privacy of our
                            customers and software application ('<b>Sorder</b>')
                            users. This policy sets out how we collect and treat
                            your personal information
                          </li>
                          <li>
                            "Personal information" is information we hold which
                            is identifiable as being about you
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Collection of personal information</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            Tharsol will, from time to time, receive and store
                            personal information you enter onto our Application,{" "}
                            <b>Sorder</b>, provided to us directly or given to
                            us in other forms
                          </li>
                          <li>
                            You may provide basic information such as your name,
                            address and email address to enable us to send
                            information, provide updates and process your
                            product or service order. We may collect additional
                            information at other times, including but not
                            limited to, when you provide feedback, when you
                            provide information about your personal or business
                            affairs, change your email preference, respond to
                            surveys and/or promotions, provide financial or
                            credit card information, or communicate with our
                            customer support
                          </li>
                          <li>
                            Additionally, we may also collect any other
                            information you provide while interacting with us
                          </li>
                          <li>
                            From time to time we required different permissions
                            regarding information collection, all the
                            permissions and data collection will only be done
                            after the user give us consent at the time of sign
                            up
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>How we collect your personal information</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            Tharsol collects personal information from you in a
                            variety of ways, including when you interact with us
                            electronically or in person, when you access our
                            Application and when we provide our services to you.
                            We may receive personal information from third
                            parties. If we do, we will protect it as set out in
                            this Privacy Policy
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Use of your personal information</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            Tharsol may use personal information collected from
                            you to provide you with information, updates and our
                            services. We may also make you aware of new and
                            additional products, services and opportunities
                            available to you. We may use your personal
                            information to improve our products and services and
                            better understand your needs
                          </li>
                          <li>
                            Tharsol may contact you by a variety of measures
                            including, but not limited to email, sms or mail
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Disclosure of your personal information</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            We may disclose your personal information to any of
                            our employees, officers, insurers, professional
                            advisers, agents, suppliers or subcontractors
                            insofar as reasonably necessary for the purposes set
                            out in this Policy. Personal information is only
                            supplied to a third party when it is required for
                            the delivery of our services
                          </li>
                          <li>
                            We may from time to time need to disclose personal
                            information to comply with a legal requirement, such
                            as a law, regulation, court order, subpoena,
                            warrant, in the course of a legal proceeding or in
                            response to a law enforcement agency request
                          </li>
                          <li>
                            We may also use your personal information to protect
                            the copyright, trademarks, legal rights, property or
                            safety of Tharsol , its application, website and
                            customers or third parties
                          </li>
                          <li>
                            Information that we collect may from time to time be
                            stored, processed in or transferred between parties
                            located in countries outside of Pakistan
                          </li>
                          <li>
                            If there is a change of control in our business or a
                            sale or transfer of business assets, we reserve the
                            right to transfer to the extent permissible at law
                            our user databases, together with any personal
                            information and non-personal information contained
                            in those databases. This information may be
                            disclosed to a potential purchaser under an
                            agreement to maintain confidentiality. We would seek
                            to only disclose information in good faith and where
                            required by any of the above circumstances
                          </li>
                          <li>
                            By providing us with personal information, you
                            consent to the terms of this Privacy Policy and the
                            types of disclosure covered by this Policy. Where we
                            disclose your personal information to third parties,
                            we will request that the third party follow this
                            Policy regarding handling your personal information
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Security of your personal information</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            Tharsol is committed to ensuring that the
                            information you provide to us is secure. In order to
                            prevent unauthorised access or disclosure, we have
                            put in place suitable physical, electronic and
                            managerial procedures to safeguard and secure
                            information and protect it from misuse,
                            interference, loss and unauthorised access,
                            modification and disclosure
                          </li>
                          <li>
                            The transmission and exchange of information is
                            carried out at your own risk. We cannot guarantee
                            the security of any information that you transmit to
                            us, or receive from us. Although we take measures to
                            safeguard against unauthorised disclosures of
                            information, we cannot assure you that personal
                            information that we collect will not be disclosed in
                            a manner that is inconsistent with this Privacy
                            Policy
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Access to your personal information</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            You may request details of personal information that
                            we hold about you in accordance with the provisions
                            of the <i>Privacy Act 1988</i> (Cth). A small
                            administrative fee may be payable for the provision
                            of information. If you would like a copy of the
                            information which we hold about you or believe that
                            any information we hold on you is inaccurate, out of
                            date, incomplete, irrelevant or misleading, please
                            email us at info@tharsol.com
                          </li>
                          <li>
                            We reserve the right to refuse to provide you with
                            information that we hold about you, in certain
                            circumstances set out in the <i>Privacy Act 1988</i>{" "}
                            (Cth)
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Software Application</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            <b>When you use our Application?</b>
                            <br />
                            &emsp;We may collect certain information such as
                            android ID, mobile operating system, the type of
                            mobile internet browsers you use, and information
                            about the way you use the Application. This
                            information is used in an aggregated manner to
                            analyse how people use our Application, such that we
                            can improve our service
                          </li>
                          <li>
                            <b>Cookies</b>
                            <br />
                            &emsp;We may from time to time use cookies on our
                            Application. Cookies are very small files which a
                            website uses to identify you when you come back to
                            the Application and to store details about your use
                            of the Application. Cookies are not malicious
                            programs that access or damage your computer, tablet
                            or smartphone. Most devices automatically accept
                            cookies but you can choose to reject cookies by
                            changing your devise settings. However, this may
                            prevent you from taking full advantage of our
                            Application
                          </li>
                          <li>
                            <b>Automatic Collection</b>
                            <br />
                            &emsp;The Application may collect certain
                            information automatically, including, but not
                            limited to, the type of mobile device you use, your
                            android ID, your mobile operating system, the type
                            of mobile internet browsers you use, and information
                            about the way you use the Application
                          </li>
                          <li>
                            <b>Third Parties</b>
                            <br />
                            &emsp;Our Application may, from time to time, have
                            links to other applications or websites not owned or
                            controlled by us. These links are meant for your
                            convenience only. Links to third party applications
                            and websites do not constitute sponsorship or
                            endorsement or approval of these third parties.
                            Please be aware that Tharsol is not responsible for
                            the privacy practises of other such applications or
                            websites. We encourage our users to be aware, when
                            they leave our Application or website, to read the
                            privacy statements of each and every application or
                            website that collects personal identifiable
                            information
                          </li>
                          <li>
                            <b>Geo-location</b>
                            <br />
                            &emsp;When you visit the Application, we may use GPS
                            technology (or other similar technology) to
                            determine your current location in order to
                            determine the city you are located within to improve
                            the user experience and display a location map with
                            relevant advertisements. We will not share your
                            current location with other users or partners
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Complaints about privacy</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            If you have any complaints about our privacy
                            practices, please feel free to send in details of
                            your complaints to Office 217,2nd Floor, Mall of
                            Faisalabad, Faisalabad, Punjab, Pakistan. We take
                            complaints very seriously and will respond shortly
                            after receiving written notice of your complaint
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Op out right</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            You can stop all collection of information by the
                            Application easily by uninstalling the Application.
                            You may use the standard uninstall processes as may
                            be available as part of your mobile device or via
                            the mobile application marketplace or network. You
                            can also request to opt-out via email, at
                            info@tharsol.com
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Changes to Privacy Policy</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            Please be aware that we may change this Privacy
                            Policy in the future. We may modify this Privacy
                            Policy at any time, in our sole discretion and all
                            modifications will be effective immediately upon our
                            posting of the modifications on our website or
                            notice board. Please check back from time to time to
                            review our Privacy Policy
                          </li>
                        </ol>
                      </li>
                      <li>
                        <b>Camera Access</b>

                        <ol className="bullet-def fs-14">
                          <li>
                            Sorder accessing camera only to get chemist practice
                            place picture.
                          </li>
                        </ol>
                      </li>
                    </ol>
                    <div className="container">
                      <hr style={{ borderStyle: "ridge" }} />
                      <p style={{ textAlign: "right" }}>Dated: 19 May 2022</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
