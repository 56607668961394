import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Carousel as MultiCarousel } from "./MultiCarousel";
import "react-multi-carousel/lib/styles.css";

import { Link } from "react-router-dom";
import Footer from "./Footer";
import Data from "./Data";
const responsive = {
  // autoPlay={true},
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

export default function Home() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  return (
    <div>
      {/* <!--[if lte IE 9]>
      <p className="browserupgrade">
        You are using an <strong>outdated</strong> browser. Please
        <a href="https://browsehappy.com/">upgrade your browser</a> to improve
        your experience and security.
      </p>
    <![endif]--> */}

      {/* <!-- ======== preloader start ======== --> */}
      {/* <div className="preloader">
        <div className="loader">
          <div className="spinner">
            <div className="spinner-container">
              <div className="spinner-rotator">
                <div className="spinner-left">
                  <div className="spinner-circle"></div>
                </div>
                <div className="spinner-right">
                  <div className="spinner-circle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- preloader end --> */}

      {/* <!-- ======== header start ======== --> */}
      <header className="header">
        <div className="navbar-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <Link to="/" className="navbar-brand">
                    <img src="assets/img/logo/logo-color.png" alt="Logo" />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="page-scroll active" href="#home">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#products">
                          Products
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="page-scroll" href="#services">
                          Services
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="#about">
                          About
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="page-scroll" href="#clients">
                          Clients
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link to="/careers" className="pagescroll">
                          Careers
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- navbar collapse --> */}
                </nav>
                {/* <!-- navbar --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- navbar area --> */}
      </header>
      {/* <!-- ======== header end ======== --> */}

      {/* <!-- ======== hero-section start ======== --> */}
      <section id="home" className="hero-section">
        <div className="container">
          <div className="row align-items-center position-relative">
            <div className="col-lg-6"></div>
            <div className="col-lg-5 mb-lg-0 mb-5 offset-lg-1 ">
              <div className="hero-content ">
                {/* <h2 className="wow fadeInUp text-white" data-wow-delay=".4s">
                  <span className="">THARSOL</span>
                  <br />
                  SOFTWARE COMPANY
                </h2> */}
                <p className="wow fadeInUp text-justify" data-wow-delay=".6s">
                  {/* Our dedicated team of experts aim to provide agility,
                  scalability, and visibility to our clients across the globe
                  for a seamless, end-to-end experience. */}
                  We are an innovative digital technology company that
                  transforms businesses through our software development
                  services. Essentially, our objective is to turn ideas into
                  finished products. Our dedicated team of experts aim to
                  provide agility, scalability, and visibility to our clients
                  across the globe for a seamless, end-to-end experience.
                </p>
                <a
                  href="#footer"
                  className="main-btn border-btn btn-hover wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  Contact Us
                </a>
                {/* <a href="#products" className="scroll-bottom">
                  <i className="lni lni-arrow-down"></i>
                </a> */}
              </div>
              {/* <div className="hero-img wow fadeInUp" data-wow-delay=".5s">
                <img src="assets/img/hero/hero-header2.png" alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== hero-section end ======== --> */}

      {/* <!-- ======== Products-section start ======== --> */}
      <section id="products" className="feature-section pt-60">
        <div className="container">
          <h2>Our Products</h2>
          <div className="row justify-content-center mt-4">
            {/* ************************** */}
            {/* ******** Product 1 ******* */}
            {/* ************************** */}

            <div className="col-10  product1 rounded-3 p-3 my-4 productCard">
              <div className="row">
                <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                  <div>
                    <h3 className="text-white">MREP</h3>
                    <p
                      className="text-light mt-4 px-md-5 px-1"
                      style={{ textAlign: "justify" }}
                    >
                      A simple yet highly efficient CRM and SFE solution, it is
                      a one-window solution to all current, as well as
                      potential, sales and marketing problems. It helps sales
                      representatives in work planning, analyzing data, and
                      implementing corporate strategies. It also alleviates the
                      friction of team communication and coordination. Moreover,
                      it presents relevant data to the field force in
                      easy-to-digest data forms such as charts, maps, and
                      graphs. In short, it puts the convenience of an office
                      into the pockets of field force personnel.
                      {/* MREP helps the sales persons in planning the work,
                      analyzing the data and implementing the strategies of
                      corporate. It also brings smoothness in team communication
                      and co-ordination. It presents the relevant data to the
                      field force in an easy to digest data forms such as
                      charts, maps and graphs. In short it brings office into
                      the pockets of field force. */}
                    </p>
                    {/* App Store Links */}
                    <div>
                      <a
                        target="_blank"
                        href="https://apps.apple.com/pk/app/mrep/id1624632238"
                        className="pe-1"
                      >
                        <img
                          height="50px"
                          src="assets/icons/appstore-img.png"
                        />
                      </a>
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/dev?id=7166957149487314090&hl=en&gl=US"
                      >
                        <img
                          height="50px"
                          src="assets/icons/googleplay-img.png"
                        />
                      </a>
                      {/* <a
                      href="http://mrep.io/"
                      className="main-btn btn-hover border-btn-1 wow fadeInUp my-4 me-2 p-2"
                    >
                     <img className="mb-6 pe-1" height={"20px"} src="assets/icons/android.png" />
                      Android 
                    </a>
                    <a
                      href="http://mrep.io/"
                      className="main-btn btn-hover border-btn-1 wow fadeInUp my-4 py-2 px-4"
                    > <img className="mb-6" height={"20px"} src="assets/icons/apple.png" />
                      IOS 
                    </a> */}
                    </div>
                    <a
                      href="http://mrep.io/"
                      className="main-btn btn-hover border-btn-1 wow fadeInUp my-4"
                    >
                      Learn More &#10145;
                    </a>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 align-self-center circle-3">
                  <div className="circle-3">
                    <img
                      // style={{ transitionDuration: "1s" }}
                      className="p1-img img-fluid circle-3"
                      src="assets/img/mrep.png"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* ************************** */}
            {/* ******** Product 2 ******* */}
            {/* ************************** */}
            <div className="col-10  product2 rounded-3 p-3 my-4 productCard">
              <div className="row">
                <div className="col-lg-6 col-sm-12 align-self-center">
                  <img
                    className="p1-img img-fluid"
                    src="assets/img/sorder0.png"
                  />
                </div>
                <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                  <div className="mt-lg-0 mt-3">
                    <h3 className="text-white">SORDER</h3>
                    <p className="text-light mt-4 px-md-5 px-1 text-justify">
                      SORDER is a complete Order Booking Management Solution. It
                      assists sales representatives in enhancing their work
                      productivity by providing the same level of effort. Not
                      only does this system offers more information to the reps,
                      but it also provides a clear direction. Overall, it serves
                      as an extra set of eyes for Sales Supervisors, allowing
                      for better decision-making and timely analysis.
                      {/* This is a smart solution for the sale field force which
                      provides easily manageable sale orders, customers & their
                      sale history, sale reports mapping and tracking etc. */}
                    </p>
                    {/* App Store Links */}
                    <div>
                      {/* <a
                        target="_blank"
                        href="https://apps.apple.com/pk/app/mrep/id1624632238"
                        className="pe-1"
                      >
                        <img
                          height="50px"
                          src="assets/icons/appstore-img.png"
                        />
                      </a> */}
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.tharsol.sorder"
                      >
                        <img
                          height="50px"
                          src="assets/icons/googleplay-img.png"
                        />
                      </a>
                    </div>

                    {/* <button className="main-btn btn-hover border-btn-1 wow fadeInUp my-4">
                      Learn More &#10145;
                    </button> */}
                  </div>
                </div>
              </div>
            </div>

            {/* ************************** */}
            {/* ******** Product 3 ******* */}
            {/* ************************** */}

            <div className="col-10  product3 rounded-3 p-3 my-4 productCard">
              <div className="row">
                <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                  <div>
                    <h3 className="text-white">M-Talib</h3>
                    <p className="text-light mt-4 px-md-5 mx-1 text-justify">
                      M-Talib is a Cloud based Integrated Learning Management
                      System which can serve the corporate training needs of
                      Businesses and Corporate. MTalib is the perfect companion
                      for delivering offline learning and microlearning.
                    </p>
                    <p className="text-light px-5 text-start">
                      With this app, learners can:
                    </p>
                    <ul className="list-group px-5">
                      <li className="text-light  text-start my-0">
                        Access assigned courses and train anywhere, anytime
                      </li>
                      <li className="text-light  text-start my-0">
                        View progress, and scores
                      </li>
                      <li className="text-light  text-start my-0">
                        Download courses for offline use and synchronize when
                        online
                      </li>
                    </ul>
                    {/* App Store Links */}
                    <div>
                      <a
                        target="_blank"
                        href="https://apps.apple.com/ag/app/mtalib/id1626420276"
                        className="pe-1"
                      >
                        <img
                          height="50px"
                          src="assets/icons/appstore-img.png"
                        />
                      </a>
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.tharsol.mtalib"
                      >
                        <img
                          height="50px"
                          src="assets/icons/googleplay-img.png"
                        />
                      </a>
                    </div>
                    {/* <button className="main-btn btn-hover border-btn-1 wow fadeInUp my-4">
                      Learn More &#10145;
                    </button> */}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 align-self-center">
                  <img
                    className="p1-img img-fluid"
                    src="assets/img/mtalib.png"
                  />
                </div>
              </div>
            </div>

            {/* ************************** */}
            {/* ******** Product 4 ******* */}
            {/* ************************** */}
            <div className="col-10  product4 rounded-3 p-3 my-4 productCard">
              <div className="row">
                <div className="col-lg-6 col-sm-12 align-self-center">
                  <img
                    className="p1-img img-fluid"
                    src="assets/img/tharsol.png"
                  />
                </div>
                <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                  <div className="mt-lg-0 mt-3">
                    <h3 className="text-white">Talluq</h3>
                    <p className="text-light mt-4 px-md-5 px-1 text-justify">
                      Talluq helps the retailers to get register with their
                      suppliers. It helps retailers to place order to their
                      suppliers and see all the products offered by retailer's
                      supplier. It also maintain the order history. In short it
                      is an order book for retailers.
                    </p>
                    {/* App Store Links */}
                    <div>
                      <a
                        target="_blank"
                        href="https://apps.apple.com/ag/app/talluq/id1560095872"
                        className="pe-1"
                      >
                        <img
                          height="50px"
                          src="assets/icons/appstore-img.png"
                        />
                      </a>
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.tharsol.talluq"
                      >
                        <img
                          height="50px"
                          src="assets/icons/googleplay-img.png"
                        />
                      </a>
                    </div>
                    <a
                      href="https://talluq.com.pk/"
                      className="main-btn btn-hover border-btn-1 wow fadeInUp my-4"
                    >
                      Learn More &#10145;
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* ************************** */}
            {/* ******** Product 5 ******* */}
            {/* ************************** */}

            <div className="col-10  product3 rounded-3 p-3 my-4 productCard">
              <div className="row">
                <div className="col-lg-6 col-sm-12 d-flex align-items-center">
                  <div>
                    <h3 className="text-white">SORDER ERP/SnD</h3>
                    <p
                      className="text-white px-md-5 px-1 mt-3 text-justify"
                      style={{ textAlign: "justify" }}
                    >
                      SORDER ERP is not a traditional sales and distribution
                      management software. It is here to break the status quo.
                      Notably, it is the first SAAS-based SnD and ERP solution
                      for distribution and secondary sales management, designed
                      to meet the distributors' and principals' needs and
                      challenges. <br /> SORDER ERP is not only an SnD system
                      with add-on features of the ERP system, but it is a fully
                      integrated cloud-hosted solution that aligns explicitly
                      with a distributor's workflow.
                      {/* As a result, principals
                      will see an increase in engagement, utilization, and
                      efficiency of the Distributor. Additionally, users are
                      likely to see increased sales and enhanced process
                      visibility. */}
                      {/* Tharsol ERP system is a fully automated and advanced ERP
                      system with Adaptive to change as per your business needs.
                      The Integration with SORDER, MREP, and DLVR mobiles apps
                      makes its cloud services a unique package of products to
                      cover the complete business cycle with convincing and ease
                      of doing business. Moreover, its advanced analytical
                      reporting tool with key performance indicators makes it
                      convenient for you to make your business decision more
                      quick and reliable. It covers a complete business cycle
                      and has built-in Purchase, Inventory, Accounts, and HRMS
                      modules. */}
                    </p>
                    <p
                      className="text-white px-md-5 px-1"
                      style={{ textAlign: "justify" }}
                    >
                      To make the integration more flexible with our solution we
                      are offering it in Oracle ADF as well as Oracle APEX
                      technology, you can choose the solution as per your need
                      and technical requirements.
                    </p>

                    <Link
                      to="/sorderERP"
                      className="main-btn btn-hover border-btn-1 wow fadeInUp my-4"
                    >
                      Learn More &#10145;
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12 align-self-center">
                  <img className="p1-img img-fluid" src="assets/img/erp.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== feature-section end ======== --> */}

      {/* <!-- ======== about-section start ======== --> */}
      <section id="services" className="about-section pt-150">
        <div className="container">
          <div className="row align-items-center">
            <div className="offset-md-3 col-md-6 col-sm-12">
              <h2 className="">
                We Provide High <span className=""> Quality Services </span>{" "}
                That Meets Your Business Needs.
              </h2>
            </div>
            <div className="col-10 offset-1 mt-5">
              <div className="row">
                <div className="col-lg-4 col-md-6  my-2">
                  <div>
                    <div className="align-center">
                      <span className=" service-icon rounded-circle">
                        <img
                          className="img-fluid "
                          src="assets/icons/services-1.png"
                        />
                      </span>
                    </div>
                    <h3>Website Development</h3>
                    <p className=" mt-2 services-text">
                      We develop user-friendly sites with modern designs.
                      Specifically, we build custom B2B and B2C portals,
                      utilizing wordpress, Vue.js, Node.js, and HTML.
                    </p>
                  </div>
                  <div>
                    <div className="align-center">
                      <span className=" service-icon rounded-circle">
                        <img
                          className="img-fluid"
                          src="assets/icons/services-3.png"
                        />
                      </span>
                    </div>
                    <h3>Mobile Development</h3>
                    <p className=" mt-2 services-text">
                      We develop user-friendly, cross-platform, and custom-built
                      mobile apps, utilizing latest technologies such as
                      React.js, Angular, Swift, and Python. We also offer
                      full-cycle services, assisting businesses with the design,
                      development, and management processes.
                    </p>
                  </div>
                  <div>
                    <div className="align-center">
                      <span className=" service-icon rounded-circle">
                        <img
                          className="img-fluid"
                          src="assets/icons/services-6.png"
                        />
                      </span>
                    </div>
                    <h3>Web Hosting</h3>
                    <p className=" mt-2 services-text">
                      We provide hosting packages with SSL certification and
                      unlimited emails. We also offer website auditing services.
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 d-lg-block my-2 d-md-none ">
                  <div className="smartphone">
                    <div className="content">
                      <Carousel
                        showStatus={false}
                        autoPlay
                        showIndicators={false}
                        showThumbs={false}
                        showArrows={false}
                        infiniteLoop={true}
                      >
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mtalib.png"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mrep1.jpg"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mrep2.jpg"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mtalib1.png"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mtalib2.png"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mtalib3.png"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mtalib4.png"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/mtalib5.png"
                          />
                        </div>
                        <div>
                          <img
                            className="rounded-b-4"
                            src="assets/img/carousel/sorder.png"
                          />
                        </div>
                      </Carousel>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 my-2">
                  <div>
                    <div className="align-center">
                      <span className=" service-icon rounded-circle">
                        <img
                          className="img-fluid"
                          src="assets/icons/services-2.png"
                        />
                      </span>
                    </div>
                    <h3>Digital Marketing</h3>
                    <p className=" mt-2 services-text">
                      We provide complete digital solutions, including social
                      media marketing, SEO optimization, and lead generation. We
                      work on various digital platforms, including Google Ads
                      and Youtube.
                    </p>
                  </div>
                  <div>
                    <div className="align-center">
                      <span className=" service-icon rounded-circle">
                        <img
                          className="img-fluid"
                          src="assets/icons/services-5.png"
                        />
                      </span>
                    </div>
                    <h3>Artificial Intelligence</h3>
                    <p className=" mt-2 services-text">
                      We utilize current AI principles and applications to
                      analyze both structured and unstructured user
                      interactions, including those occurring across countries.
                      As a result, we are able to provide meaningful insights
                      regarding users' needs, behaviors, sentiments, and
                      experiences (UX/UI).
                    </p>
                  </div>
                  <div>
                    <div className="align-center">
                      <span className=" service-icon rounded-circle">
                        <img
                          className="img-fluid"
                          src="assets/icons/services-4.png"
                        />
                      </span>
                    </div>
                    <h3>Software Development</h3>
                    <p className=" mt-2 services-text">
                      We design, program, test, deploy, and maintain software
                      deliverables used in creating frameworks, programs, and
                      applications.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== about-section end ======== --> */}
      {/* <!-- ======== about2-section start ======== --> */}
      <section id="about" className="about-section pt-50">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-6">
              <div className="about-content mb-5 mb-md-0">
                <div className="section-title mb-30">
                  <h2 className="mb-25 wow fadeInUp" data-wow-delay=".2s">
                    OUR COMPANY
                  </h2>
                  <p
                    className="wow fadeInUp"
                    data-wow-delay=".4s"
                    style={{ textAlign: "justify" }}
                  >
                    We are an innovative digital technology company that
                    transforms businesses through our software development
                    services. Essentially, our objective is to turn ideas into
                    finished products.
                    <br /> Our dedicated team of experts aim to provide agility,
                    scalability, and visibility to our clients across the globe
                    for a seamless, end-to-end experience.
                    {/* Since its inception, it has grown and progressed to be at
                    the center stage of information and technology, providing
                    effective computing strategies and solutions to Private and
                    Government Organizations. IT Empire has played a major role
                    in some of the largest IT projects in the country.
                    Internationally, the Company has established itself as a key
                    player in the critical market segments of different
                    countries, providing services & products to a growing list
                    of corporate clients. Our diverse expertise extends beyond
                    deployment to provide operational, maintenance, support and
                    business outsourcing services. Our Technology Specialists
                    have years of experience delivering successful solutions */}
                  </p>
                </div>

                {/* <a
                  href="javascript:void(0)"
                  className="main-btn btn-hover border-btn-2 wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  Learn More
                </a> */}
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 order-first order-lg-last">
              <div className="about-img-2">
                <img
                  src="assets/img/about/office1.png"
                  alt=""
                  className="w-100 rounded "
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======== about2-section end ======== --> */}

      {/* <!-- ======== feature-section start ======== --> */}
      <section id="clients" className="feature-extended-section">
        <h2 className="mb-5 pb-5">Notable Clients</h2>
        <div className="feature-extended-wrapper px-5">
          <MultiCarousel
            responsive={responsive}
            autoPlay={true}
            infinite={true}
          >
            {Data.Clients.map((d) => {
              return (
                <div key={d.img}>
                  <img className="clientsLogo jobCard " src={d.img} alt="" />
                </div>
              );
            })}
          </MultiCarousel>
        </div>
      </section>
      {/* <!-- ======== feature-section end ======== --> */}

      {/* <!-- ======== subscribe-section start ======== --> */}
      {/* <section id="contact" className="subscribe-section pt-120">
        <div className="container">
          <div className="subscribe-wrapper img-bg">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-7">
                <div className="section-title mb-15">
                  <h2 className="text-white mb-25">Subscribe Our Newsletter</h2>
                  <p className="text-white pr-5">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor
                  </p>
                </div>
              </div>
              <div className="col-xl-6 col-lg-5">
                <form action="#" className="subscribe-form">
                  <input
                    type="email"
                    name="subs-email"
                    id="subs-email"
                    placeholder="Your Email"
                  />
                  <button type="submit" className="main-btn btn-hover">
                    Subscribe
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ======== subscribe-section end ======== --> */}

      <Footer />

      {/* **************** Copy Right Section ********************** */}
      {/* <!-- ======== scroll-top ======== --> */}
      <a href="#home" className="scroll-top btn-hover fs-2">
        {/* <i className="lni lni-chevron-up"></i> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="currentColor"
          class="bi bi-arrow-up-short"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5z"
          />
        </svg>
        {/* &#8593; */}
        <span className="fa fa-arrow" />
      </a>
    </div>
  );
}
