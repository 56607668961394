import React, { useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import Data from "./Data";
import moment from "moment";
import { Link } from "react-router-dom";

export default function Careers() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      {/* <Header /> */}
      {/* <!-- ======== header start ======== --> */}
      <header className="header">
        <div className="navbar-area bg-dark">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <Link to="/" className="navbar-brand">
                    <img src="assets/img/logo/logo-color.png" alt="Logo" />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="pagescroll " href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          Products
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          Services
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          About
                        </a>
                      </li>

                      <li className="nav-item">
                        <a className="pagescroll" href="/">
                          Clients
                        </a>
                      </li>
                      <li className="nav-item">
                        <Link to="/careers" className="pagescroll active">
                          Careers
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* <!-- navbar collapse --> */}
                </nav>
                {/* <!-- navbar --> */}
              </div>
            </div>
            {/* <!-- row --> */}
          </div>
          {/* <!-- container --> */}
        </div>
        {/* <!-- navbar area --> */}
      </header>
      {/* <!-- ======== header end ======== --> */}

      <div>
        <div className="container mt-5 pt-5">
          <div className="row mt-5 pt-5">
            <div className="col-md-6 col-xs-12 align-self-center ">
              <h1>Careers at Tharsol</h1>
              <p className="text-justify text-last-center mt-4 px-4">
                Tharsol is one of the fastest growing software services
                companies in Pakistan. We have managed to rapidly build and grow
                a world class team of engineers ready to take on diverse and
                challenging technology development projects. Tharsol provides
                competitive salaries and great benefits. If you want to broaden
                your technical skills, building software that affects users
                around the world and working in an environment that thrives on
                creativity and product innovation, then be a part of our team
                and grow with us. Tharsol will give you the opportunity to
                develop further and fulfill your true potential.
              </p>
              <p>
                Email at{" "}
                <a href="mailto:support@tharsol.com">
                  <span className="text-warning">support@tharsol.com</span>
                </a>
              </p>
            </div>
            <div className="col-md-6 d-flex d-md-block d-none">
              <img src="assets/img/careers.png" />
            </div>
          </div>
        </div>

        {/* Openings */}
        <div>
          <h1 className="text-dark">Current Openings</h1>
          <div
            className="mt-5"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}
          >
            <div className="container py-5 ">
              <div className="row">
                {Data.Jobs.map((d, i) => {
                  return (
                    <div className="col-md-4 col-sm-6 col-xs-12 my-3 jobCard">
                      <div className="p-4 bg-white rounded-3 shadow">
                        <h5>{d.title}</h5>
                        <p className="pt-2 text-secondary fs-14 ">
                          {d.positions} Positions (s)
                        </p>
                        <p className="pt-0 text-secondary fs-14 ">
                          <span className="fw-bold">Last Date : </span>{" "}
                          {moment().add(5, "days").format("DD MMM YYYY")}
                        </p>
                        <hr className="w-25 mx-auto" />
                        <p className="text-secondary">Tharsol</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
